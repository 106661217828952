import React, { useEffect, useState, Fragment } from 'react';
import { Modal, Button, Table, Row, Badge, Col } from 'react-bootstrap';
import apiFc from '../../services/APIFc';
import InputCarritoCompra from './InputCarritoCompra';
import { StockLevel, getProductBadgeInfo, isUserLogued } from '../../functions/Procedimientos';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import { decryptData } from '../../functions/cryptoUtils';
import { AlertDialogInterface } from './Interfaces';
import { Snackbar } from '@mui/material';
import { isMobile } from 'react-device-detect';

interface Articulo {
  articuloCodigo: string;
  articuloDescripcion: string;
  articuloUnidadVenta: string;
  articuloPrecio: number;
  articuloPrecioVentaSug1: number;
  articuloPrecioVentaSug2: number;
  articuloTasaIVA: number;
  articuloStock: number;
  articuloStockMin: number;
  articuloStockMax: number;
  articuloImagenGXI: string | null;
  articuloGrupoId: { articuloGrupoImagenGXI: string | null } | null;
  articuloCantidadMultiplo: any;
}

interface ExtraLargeModalProps {
  show: boolean;
  handleClose: () => void;
  selectedGroup: any;
  alertDialog: AlertDialogInterface;
  // setAlertDialog: React.Dispatch<React.SetStateAction<AlertDialogInterface>>;
  windowWidth: number;
}

const ExtraLargeModal: React.FC<ExtraLargeModalProps> = ({
  show,
  handleClose,
  selectedGroup,
  // alertDialog,
  // setAlertDialog,
  windowWidth
}) => {
  const [localArticulosGrupo, setLocalArticulosGrupo] = useState<Articulo[]>([]);
  const user = useSelector((store: any) => decryptData(store.authreducer.user));
  const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
    id: "1",
    visible: false,
    severity: 'success', // severity="error" / "warning" / "info" / "success"
    timeOut: 2000, // severity="error" / "warning" / "info" / "success"
    cabecera: "Agregado!",
    texto: "el producto fue agregado.",
  });
  useEffect(() => {
  }, [alertDialog]);

  function Mensaje(props: any) {
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
      props,
      ref,
    ) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setAlertDialog({ ...alertDialog, visible: false });
    };
    return (
      <Snackbar
        open={alertDialog.visible}
        autoHideDuration={alertDialog.timeOut}
        onClose={handleClose}
        //Aca decido si es mobile muestro arriba el cartelito, sino abajo
        anchorOrigin={(isMobile) ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleClose}
          severity={(alertDialog.severity == "success") ? 'success' : 'warning'}
          sx={{ width: '100%' }}>
          <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
        </Alert>
      </Snackbar>
    )
  }


  useEffect(() => {
    if (selectedGroup.articuloGrupoId && selectedGroup.articuloGrupoId > 0) {
      try {
        apiFc.get(`/articuloGrupos/${selectedGroup.articuloGrupoId}/articulos`).then(res => {
          setLocalArticulosGrupo(res.data);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [selectedGroup]);

  const handleModalClose = () => {
    setLocalArticulosGrupo([]);
    handleClose();
  };

  const [allSameImage, setAllSameImage] = useState(false);
  const [commonImage, setCommonImage] = useState('');

  useEffect(() => {
    if (localArticulosGrupo && localArticulosGrupo.length > 0) {
      const firstImage = localArticulosGrupo[0].articuloImagenGXI;
      const allSame = localArticulosGrupo.every(articulo => articulo.articuloImagenGXI === firstImage);
      setAllSameImage(allSame);
      if (firstImage) {
        setCommonImage(firstImage);
      }
    }
  }, [localArticulosGrupo]);



  return (
    <>
      {alertDialog.visible && <Mensaje ></Mensaje>}
      <Modal
        dialogClassName="modal-90w"
        show={show}
        onHide={handleModalClose}
        aria-labelledby="example-modal-sizes-title-xl"
      >
        <Modal.Header>
          <div className="d-flex align-items-center">
            {allSameImage && commonImage && (
              <img
                className="header-image"
                src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${commonImage}`}
                alt="group"
                style={{ width: '50px', height: '50px', marginRight: '10px' }}
                onError={(e) => {
                  (e.target as HTMLImageElement).src = 'https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png';
                }}
              />
            )}
            <Modal.Title>{selectedGroup.articuloGrupoDescripcion}</Modal.Title>
          </div>
          <Button onClick={handleModalClose} className="btn-close" variant="">
            x
          </Button>
        </Modal.Header>

        <Modal.Body>
          <div className="product-details table-responsive">
            {windowWidth < 1200 ? (
              localArticulosGrupo && localArticulosGrupo.map((articulo, index) => {
                const { badgeBg, badgeText, badgeClassName } = getProductBadgeInfo(articulo);
                const showImage = !allSameImage && articulo.articuloImagenGXI !== null; // Verifica si hay imagen y no es repetida
                return (
                  <Row key={articulo.articuloCodigo}>
                    {showImage && (
                      <Col>
                        <div className="card-aside-img">
                          <img
                            className="pic-1 wd-200 ht-200"
                            src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${articulo.articuloImagenGXI}`}
                            alt="product"
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = 'https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png';
                            }}
                          />
                        </div>
                      </Col>
                    )}
                    <Col>
                      <Row>
                        <h6 className="font-weight-semibold mt-0 text-uppercase">
                          {articulo.articuloDescripcion}
                        </h6>
                      </Row>
                      <Row>
                        <Col>
                          <div className="media-body">
                            <div className="card-item-desc mt-0">
                              <div className="float-right">
                                <dl className="card-item-desc-1">
                                  <dt>Código:</dt>
                                  <dd>{articulo.articuloCodigo}</dd>
                                </dl>
                                <dl className="card-item-desc-1">
                                  <dt>Unidad de venta:</dt>
                                  <dd>{articulo.articuloUnidadVenta}</dd>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <dt className="d-flex m-auto">
                            <Badge bg={badgeBg} className={badgeClassName}>
                              {badgeText}
                            </Badge>
                          </dt>
                        </Col>
                      </Row>
                    </Col>
                    <Table className="table table-bordered mb-0 text-nowrap">
                      <thead>
                        <tr>
                          {isUserLogued() ? (
                            <>
                              {user?.clienteConfiguracionMostrarCos ? <th>Costo</th> : null}
                              <th>Sug 1</th>
                              <th>Sug 2</th>
                              <th>Stock</th>
                            </>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {user?.clienteConfiguracionMostrarCos ? (
                            <td className="text-end text-lg text-medium font-weight-bold tx-15">
                              {articulo.articuloPrecio}
                            </td>
                          ) : null}
                          <td className="text-end text-lg text-medium font-weight-bold tx-15">
                            {user?.clienteConfiguracionMostrarIVA
                              ? (articulo.articuloPrecioVentaSug1 * (1 + articulo.articuloTasaIVA / 100)).toFixed(2)
                              : articulo.articuloPrecioVentaSug1}
                          </td>
                          <td className="text-end text-lg text-medium font-weight-bold tx-15">
                            {user?.clienteConfiguracionMostrarIVA
                              ? (articulo.articuloPrecioVentaSug2 * (1 + articulo.articuloTasaIVA / 100)).toFixed(2)
                              : articulo.articuloPrecioVentaSug2}
                          </td>
                          <td className="text-center font-weight-bold tx-15">
                            <StockLevel
                              stock={articulo.articuloStock}
                              stockMin={articulo.articuloStockMin}
                              stockMax={articulo.articuloStockMax}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th className="flex-row justify-content-center" colSpan={4}>
                            <InputCarritoCompra
                              articulo={articulo}
                              origen="detalleArticulo"
                              onEvent={(e: any) => setAlertDialog(e)}
                              className="wd-important"
                            />
                          </th>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                );
              })
            ) : (
              <Table className="table table-bordered table-hover mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th className="text-start">Producto</th>
                    {isUserLogued() ? (
                      <>
                        <th className="w-150">Cantidad</th>
                        {user?.clienteConfiguracionMostrarCos ? <th>Costo</th> : null}
                        <th>Sug 1</th>
                        <th>Sug 2</th>
                        <th>Stock</th>
                        <th>Informacion</th>
                      </>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {localArticulosGrupo && localArticulosGrupo.map((articulo) => {
                    const { badgeBg, badgeText, badgeClassName } = getProductBadgeInfo(articulo);
                    return (
                      <React.Fragment key={articulo.articuloCodigo}>
                        <tr>
                          <td>
                            <div className="media">
                              <div className="card-aside-img">
                                {articulo.articuloImagenGXI !== null ? (
                                  <img
                                    className="pic-1"
                                    src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${articulo.articuloImagenGXI}`}
                                    alt="product"
                                    onError={(e) => {
                                      (e.target as HTMLImageElement).src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`;
                                    }}
                                  />
                                ) : articulo.articuloGrupoId?.articuloGrupoImagenGXI !== null ? (
                                  <img
                                    className="pic-1"
                                    src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${articulo?.articuloGrupoId?.articuloGrupoImagenGXI}`}
                                    alt="product"
                                    onError={(e) => {
                                      (e.target as HTMLImageElement).src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`;
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="pic-1"
                                    src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`}
                                    alt="product"
                                  />
                                )}
                              </div>
                              <div className="media-body">
                                <div className="card-item-desc mt-0">
                                  <h6 className="font-weight-semibold mt-0 text-uppercase">
                                    {articulo.articuloDescripcion}
                                  </h6>
                                  <dl className="card-item-desc-1">
                                    <dt>{`Codigo: `}</dt>
                                    <dd>{`${articulo.articuloCodigo}`}</dd>
                                  </dl>
                                  <dl className="card-item-desc-1">
                                    <dt>{`U.v: `}</dt>
                                    <dd>{`${articulo.articuloUnidadVenta}`}</dd>
                                  </dl>
                                  <dl className="card-item-desc-1">
                                    <dt>
                                      <Badge bg={badgeBg} className={badgeClassName}>
                                        {badgeText}
                                      </Badge>
                                    </dt>
                                  </dl>
                                </div>
                              </div>
                            </div>
                          </td>
                          {isUserLogued() ? (
                            <>
                              <td className="text-center">
                                <InputCarritoCompra
                                  articulo={articulo}
                                  origen="detalleArticulo"
                                  onEvent={(e: AlertDialogInterface) =>
                                    setAlertDialog(e)
                                  }
                                  className="wd-important"
                                />
                              </td>
                              {user?.clienteConfiguracionMostrarCos ? (
                                <td className="text-end text-lg text-medium font-weight-bold tx-15">
                                  {articulo.articuloPrecio}
                                </td>
                              ) : null}
                              <td className="text-end text-lg text-medium font-weight-bold tx-15">
                                {user?.clienteConfiguracionMostrarIVA
                                  ? (articulo.articuloPrecioVentaSug1 * (1 + articulo.articuloTasaIVA / 100)).toFixed(2)
                                  : articulo.articuloPrecioVentaSug1}
                              </td>
                              <td className="text-end text-lg text-medium font-weight-bold tx-15">
                                {user?.clienteConfiguracionMostrarIVA
                                  ? (articulo.articuloPrecioVentaSug2 * (1 + articulo.articuloTasaIVA / 100)).toFixed(2)
                                  : articulo.articuloPrecioVentaSug2}
                              </td>
                              <td className="text-center font-weight-bold tx-15">
                                <StockLevel
                                  stock={articulo.articuloStock}
                                  stockMin={articulo.articuloStockMin}
                                  stockMax={articulo.articuloStockMax}
                                />
                              </td>
                              <td>
                                {articulo.articuloUnidadVenta}{" "}
                                {articulo.articuloCantidadMultiplo !== 0 && (
                                  <Button
                                    variant=""
                                    className="btn btn-warning paso4"
                                    type="button"
                                  // onClick={handleOpenModal}
                                  >
                                    <i className="fa fa-info"></i>
                                  </Button>
                                )}
                                <Modal
                                // show={showModal}
                                // onHide={handleCloseModal}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Header className='wd-100p'>
                                      <Modal.Title>Informacion</Modal.Title>
                                      <Button
                                        onClick={handleClose}
                                        className="btn-close"
                                        variant=""
                                      >
                                        x
                                      </Button>
                                    </Modal.Header>
                                  </Modal.Header>
                                  <Modal.Body>
                                    {/* <TablaDetalle /> */}
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button variant="secondary"
                                    // onClick={handleCloseModal}
                                    >
                                      Salir
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </td>
                            </>
                          ) : null}
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-light" onClick={handleModalClose}>
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExtraLargeModal;
